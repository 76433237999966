import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Upload = Loadable(lazy(() => import("src/pages/admin/upload/index")));

const upload = [
  {
    path: "upload",
    children: [{ index: true, element: <Upload /> }],
  },
];

export default upload;
