import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Backdrop,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import addVisitor from "src/api/global";
import { object, string } from "yup";
import { FormProvider, RHFTextField } from "../hook-form";
import Ternary from "../ternary";
import Icons from "./components/icons";
import InputField from "./components/input-field";
import LeftPane from "./components/left-pane";
const schema = object().shape({
  name: string().required("Name is required"),
  email: string()
    .email("Enter a valid email address")
    .required("Name is required"),
});

const defaultValues = {
  name: "",
  email: "",
  skype: "",
  whatsapp: "",
  telegram: "",
  type: "",
  message: "",
};

const Visitor = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataCollected, setDataCollected] = useState(() =>
    JSON.parse(localStorage.getItem("data-collected"))
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (dataCollected) {
      body.style.overflow = "unset";
      return;
    }
    setTimeout(() => {
      body.style.overflow = "hidden";

      setOpen(true);
    }, 5000);
  }, []);

  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });

  const onSubmit = async (inputData) => {
    const { status, message } = await addVisitor(inputData);
    if (status) {
      enqueueSnackbar(message);
      localStorage.setItem("data-collected", true);
      setDataCollected(true);
      setOpen(false);
      return;
    }
    enqueueSnackbar(message, { variant: "error" });
  };

  const {
    setValue,
    formState: { isSubmitting },
  } = methods;
  const { breakpoints } = useTheme();
  const u_sm = useMediaQuery(breakpoints.up("sm"));

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <FormProvider
          methods={methods}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Paper
            sx={{
              width: "80vw",
              maxWidth: "900px",
              height: "auto",
              maxHeight: "600px",
              padding: 0.5,
            }}
            component={Stack}
            direction="row"
            spacing={3}
          >
            <Ternary when={u_sm} then={<LeftPane open={open} />} />
            <Box
              sx={{
                paddingTop: 3,
                padding: 3,
                overflowY: "auto",
              }}
            >
              <InputField />
              <FormControl sx={{ mb: 3 }}>
                <FormLabel>
                  <Typography fontWeight={700} mt={3} color="initial">
                    What kind of website do you need?
                  </Typography>
                </FormLabel>
                <RadioGroup row={u_sm} onChange={(_, v) => setValue("type", v)}>
                  <FormControlLabel
                    value="app"
                    control={<Radio />}
                    label="App"
                  />
                  <FormControlLabel
                    value="website"
                    control={<Radio />}
                    label="Website"
                  />
                  <FormControlLabel
                    value="mlm"
                    control={<Radio />}
                    label="MLM"
                  />
                </RadioGroup>
              </FormControl>

              <RHFTextField
                InputLabelProps={{ shrink: true }}
                variant="standard"
                label="Message"
                name="message"
                multiline
                rows={4}
                placeholder="Give a short description for your product"
              />
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "right",
                  display: "flex",
                }}
              >
                <LoadingButton
                  loading={isSubmitting}
                  fullWidth={!u_sm}
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    alignSelf: "right",
                    mt: 4,
                    mb: 3,
                  }}
                >
                  Message
                </LoadingButton>
              </Box>
              <Ternary
                when={!u_sm}
                then={
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Icons color="#000" mt={0} mb={3} />
                  </Box>
                }
              />
            </Box>
          </Paper>
        </FormProvider>
      </Backdrop>
    </>
  );
};

export default Visitor;
